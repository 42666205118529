<template>
  <div class="reportBox" :style="{ width: screenW }">
    <h1>检验检测报告公示</h1>
    <div v-if="reportData && reportData.reportNo" :style="{height:`calc(100vh - ${isPhoneSafari?240:176}px)`}">
      <label>机构名称：</label>
      <p>{{ reportData.companyName }}</p>
      <!-- <label>CMA资质号：</label>
      <p>{{ reportData.cmaQualification }}</p>
      <label>安全生产资质号：</label>
      <p>{{ reportData.safetyProdQualification }}</p>
      <label>CNAS认可号：</label>
      <p>{{ reportData.cnasRecognition }}</p> -->
      <label>报告编号：</label>
      <p>{{ reportData.reportNo }}</p>
      <label>样品名称：</label>
      <p>{{ reportData.sampleName }}</p>
      <label>规格型号：</label>
      <p>{{ reportData.specModel }}</p>
      <label>委托人(单位)：</label>
      <p>{{ reportData.wtOrgName }}</p>
      <label>检验检测类别：</label>
      <p>{{ reportData.inspectionTestingType }}</p>
      <label>报告发布日期：</label>
      <p>{{ reportData.reportReleaseDate }}</p>
      <!-- <label>检验检测开始日期：</label>
      <p>{{ reportData.checkStartDate }}</p>
      <label>检验检测结束日期：</label>
      <p>{{ reportData.checkEndDate }}</p>
      <label>检验检测地点：</label>
      <p>{{ reportData.checkPlace }}</p> -->
      <label>报告结论：</label>
      <p>{{ reportData.conclusion }}</p>
      <!-- <label>所属行业：</label>
      <p>{{ reportData.industry }}</p>
      <label>项目组长：</label>
      <p>{{ reportData.projectLeader }}</p> -->
      <label>勘验图像：</label>
      <p style="line-height: 21px;" v-if="!reportData.pictures || !reportData.pictures.length">暂无图像</p>
      <div v-if="reportData.pictures && reportData.pictures.length>0" class="imgBox">
        <el-image
          v-for="(v,i) in reportData.pictures"
          :key="i" 
          style="width: 97px;height: 97px;border-radius: 8px;" 
          :src="v.picReqUrl" 
          :preview-src-list="pictures">
        </el-image>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ReportDetail",
  data() {
    return {
      screenW: 0,
      reportData:{
        pictures:[]
      },
      isPhoneSafari:false
    }
  },
  computed: {
    pictures() {
      let pics = this.reportData.pictures.map((v) => v.picReqUrl);
      return pics;
    }
  },
  mounted() {
    // 是否是苹果手机自带的浏览器
    const userAgent = navigator.userAgent;
    this.screenW = userAgent.indexOf('Mobile')>-1?window.screen.width + 'px':window.innerWidth + 'px';
    this.isPhoneSafari = userAgent.indexOf('iPhone')>-1 && userAgent.indexOf('Safari')>-1;
    // 手机查看截止拖动
    document.getElementsByTagName('html')[0].style.position = 'fixed';
    this.getReport();
  },
  methods:{
    getReport(){
      this.reportData = {
        pictures:[]
      }
      this.$post(`${this.$requestUrl}qingzhi-admin/web/getByReportNo`,{reportNo: this.$route.query.reportNo}).then(res => {
        if(res.code===500){
          this.$message.error(res.msg);
          return
        }
        if(res.report===null){
          this.$message({
            type:'error',
            duration:5000,
            message:"未查询到信息，请核查信息后再次查询。如有疑问请进一步联系相关业务人员，谢谢！"
          })
          return
        }
        this.reportData = res.report;
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.reportBox {
  height: 129px;
  background: linear-gradient(180deg, #007BC7 0%, #007BC7 85%, #FFFFFF 100%);

  &>div {
    width: 85%;
    background: #FFF;
    box-shadow: 0px 8px 24px 1px rgba(0, 0, 0, 0.09);
    border-radius: 16px;
    padding: 17px 20px;
    margin: 0 auto;
    overflow: auto;

    label {
      font-size: 14px;
      color: #666;
      line-height: 20px;
    }

    p {
      line-height: 21px;
      font-size: 15px;
      font-weight: bold;
      color: #333;
      margin-bottom: 16px;
    }
  }
  .imgBox{
    margin-top: 3px;
    display: flex;
    flex-wrap: wrap;

    .el-image{
      margin-bottom: 3px;
      margin-right: 3px;
    }
  }
}

h1 {
  font-size: 23px;
  font-weight: bold;
  color: #FFF;
  margin-block-start: 0;
  margin-block-end: 0;
  line-height: 32px;
  padding: 30px 0 19px 0;
  text-align: center;
}
</style>